/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */
export default (builder) =>
  builder.mutation({
    query: ({
      collectionId,
      query,
      acceptLanguage,
      includeMetadata,
      conversationId,
      chatHistoryId,
      filters,
    }) => {
      return {
        url: `file-collections/${collectionId}/chat?q=${encodeURIComponent(
          query,
        )}&metadata=${includeMetadata}`,
        method: 'POST',
        body: {
          acceptLanguage: acceptLanguage,
          conversationId: conversationId,
          chatHistoryId: chatHistoryId,
          filters: filters,
        },
      };
    },
    invalidatesTags: ['conversation'],
  });
