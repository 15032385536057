import React from 'react';
import { Table, TableBody, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  CardContentStyled,
  CardHeaderStyled,
  CardStyled,
  TableCellStyled,
  TableRowStyled,
} from './styled';

const DatabaseSchemaNodeHeader = ({ children }) => {
  return (
    <CardHeaderStyled
      title={
        <Typography variant="subtitle2" align="center">
          {children}
        </Typography>
      }
    />
  );
};

DatabaseSchemaNodeHeader.propTypes = {
  children: PropTypes.node,
};

const DatabaseSchemaNodeBody = ({ children }) => {
  return (
    <CardContentStyled>
      <Table>
        <TableBody>{children}</TableBody>
      </Table>
    </CardContentStyled>
  );
};

DatabaseSchemaNodeBody.propTypes = {
  children: PropTypes.node,
};

const DatabaseSchemaTableRow = ({ children }) => {
  return <TableRowStyled>{children}</TableRowStyled>;
};

DatabaseSchemaTableRow.propTypes = {
  children: PropTypes.node,
};

const DatabaseSchemaTableCell = ({ children }) => {
  return <TableCellStyled>{children}</TableCellStyled>;
};

DatabaseSchemaTableCell.propTypes = {
  children: PropTypes.node,
};

const DatabaseSchemaNode = ({ selected, children }) => {
  return (
    <CardStyled
      variant={selected ? 'outlined' : 'elevation'}
      selected={selected}
    >
      {children}
    </CardStyled>
  );
};

DatabaseSchemaNode.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.bool,
};

export {
  DatabaseSchemaNode,
  DatabaseSchemaNodeHeader,
  DatabaseSchemaNodeBody,
  DatabaseSchemaTableRow,
  DatabaseSchemaTableCell,
};
