/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getIngestedFiles = (builder) =>
  builder.query({
    query: ({ collectionId, pageNo, pageSize, query, fileType }) => {
      const params = new URLSearchParams();
      if (pageNo) params.append('pageNo', pageNo);
      if (pageSize) params.append('pageSize', pageSize);
      if (query) params.append('query', query);
      if (fileType) params.append('fileType', fileType);

      return {
        url: `file-collections/${collectionId}/files?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['ingestedFiles', 'individualFileCollections'],
  });

export const getAllFileCollectionsForTable = (builder) =>
  builder.query({
    query: ({ pageNo, pageSize, query }) => {
      const params = new URLSearchParams();
      if (query) params.append('query', query);
      if (pageNo) params.append('pageNo', pageNo);
      if (pageSize) params.append('pageSize', pageSize);
      return {
        url: `file-collections/table?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['file-collections-table'],
  });

export default (builder) =>
  builder.query({
    query: ({ exclude, include, isStoreCreated }) => {
      const params = new URLSearchParams();

      params.append('include', include?.join(','));
      exclude?.forEach((item) => {
        params.append('exclude', item);
      });
      return {
        url: `file-collections?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['fileCollections'],
  });
