//   (?:         # begin non-capturing group
//     \.        #   a dot
//     (         #   begin capturing group (captures the actual extension)
//       [^.]+   #     anything except a dot, multiple times
//     )         #   end capturing group
//   )?          # end non-capturing group, make it optional
//   $           # anchor to the end of the string
export const extensionRegEx = /(?:\.([^.]+))?$/;

export const emailRegEx =
  /([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})/g;
