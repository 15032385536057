import {
  Alert,
  Box,
  Button,
  Icon,
  Typography,
  LinearProgress,
  styled,
  Grid,
} from '@mui/material';
import { FileUpload } from '@mui/icons-material';
import fontWeights from '../../themes/fontWeights';
import { color } from '@uiw/react-codemirror';
import colors from '../../themes/colors';
export const HistoryButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  borderTopLeftRadius: 0,
  borderEndStartRadius: 0,
  zIndex: 1,
}));

export const SearchBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    paddingX: theme.spacing(8),
  },
  [theme.breakpoints.down('sm')]: {
    paddingX: theme.spacing(1),
  },
}));

export const LoadingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
}));

export const ShadowBox = styled(Box)(({ theme }) => ({
  //boxShadow: '0px 0px 68px rgba(0, 0, 0, 0.15)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3, 0),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
  background: 'transparent',
}));

export const StyledLinearProgress = styled(LinearProgress)({
  width: '40%',
});

export const StyledIcon = styled(Icon)(({ theme }) => ({
  width: 91,
  height: 38,
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(-1),
  color: theme.palette.primary.main,
}));

export const ShadowBoxNormal = styled(Box)(({ theme }) => ({
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(0),
  padding: theme.spacing(3),
  width: '100%',
  backgroundColor: theme.palette.common.white,
  marginTop: theme.spacing(1),
}));

export const AnswerComponentWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(0, 4),
}));

export const AppTitleBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}));

export const LoadingTypography = styled(Typography)({
  textAlign: 'center',
  margin: 3,
});

export const ResultBox = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
}));

export const FoundDataAlert = styled(Alert)({
  background: '#33C000',
  color: '#ffffff !important',
  mb: 2,
  '.MuiAlert-icon': { alignItems: 'center', color: '#fff' },
  '.MuiAlert-message': { wordBreak: 'break-all' },
});

export const ResultTitle = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(4, 0, 3),
  fontWeight: '300',
  fontSize: '24px',
  color: theme.palette.text.caption,
  display: 'flex',
  justifyContent: 'space-between',
}));

export const MetaButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const HoveringLink = styled(Button)(({ theme }) => ({
  borderRadius: 4,
  paddingX: theme.spacing(2.5),
  cursor: 'pointer',
  color: theme.palette.primary.main,
  fontSize: 14,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const MetaDataGridBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(1),
  boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
  paddingRight: `${theme.spacing(4)}`,
  paddingLeft: `${theme.spacing(4)}`,
}));

export const InfographicSVG = styled('img')(({ theme }) => ({
  height: 130,
  margin: '35px 0',
  padding: '35px 0',

  [theme.breakpoints.down('md')]: {
    height: 80,
    margin: '15px 0',
    padding: '15px 0',
  },

  [theme.breakpoints.down('sm')]: {
    height: 70,
    margin: '10px 0',
    padding: '10px 0',
  },
}));

export const FilterBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 460,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.19)',
  marginTop: 0,
  marginBottom: 20,
  boxSizing: 'border-box',
  padding: 15,
  paddingRight: 0,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.19)',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const UploadFileBox = styled(Box)(({ theme }) => ({
  width: 150,
  height: 150,
  backgroundImage:
    "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%23003E51FF' stroke-width='2' stroke-dasharray='10' stroke-dashoffset='18' stroke-linecap='square'/%3e%3c/svg%3e\")",
  borderRadius: '30px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '5%',
  marginBottom: theme.spacing(2),
  transform: 'translateX(-15%)',
  '&:hover': {
    cursor: 'pointer',
  },
  [theme.breakpoints.down('md')]: {
    width: '98%',
    height: '95px',
    marginLeft: '27%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const FilterTableBox = styled(Box)(({ theme, permission }) => ({
  height: 500,
  width: permission == 'deny' ? '100%' : '80%',
  paddingRight: '14px',
  paddingBottom: '8px',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const FileUploadIcon = styled(FileUpload)(({ theme, permission }) => ({
  fontSize: 30,
  [theme.breakpoints.down('md')]: {
    fontSize: 20,
  },
}));

export const MetaGrid = styled(Grid)(({ theme, isSmallScreen }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingX: isSmallScreen ? '0px' : '16px',
  paddingY: isSmallScreen ? '0px' : '16px',
  fontSize: '14px',
}));

export const UploadFilesTitleConatiner = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginLeft: '-8px',
}));

export const UploadFilesSubTitleConatiner = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '10px',
}));

export const FileNameBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const FileSizeText = styled(Typography)(({ theme }) => ({
  fontSize: '10px',
  color: theme.typography.caption,
}));

export const FilterFooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 10,
  padding: 10,
}));

export const StatusText = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

export const InfographicText = styled(Typography)(({ theme }) => ({
  fontSize: '56px',
  fontWeight: 200,
  color: '#131E29',
  marginTop: '100px',
}));
