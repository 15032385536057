import moment from 'moment';
import { usePdfFetch } from '../redux/services/speciphicAsk/pdf.ask.api';
import { DEFAULT_FORMAT, INPUT_FORMAT } from '../constants/timeFormats';

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const LOAD_TITLE = (document, title) => {
  const baseTitle = document.title;
  document.title = `${baseTitle} ${title}`;

  return () => {
    document.title = baseTitle;
  };
};

export const truncateString = (str, len = 30) => {
  if (str.length > len) {
    return str.substring(0, len) + '...';
  }
  return str;
};

/**
 * Converts a string from the format "XXXX_XXX" to "Xxxx Xxx" format.
 *
 * @param {string} inputString - The string to convert.
 * @returns {string} The converted string in title case format.
 */
export const convertToTitleCase = (inputString) => {
  // Split the input string by underscore
  const parts = inputString.split('_');

  // Convert each part to title case
  const titleCaseParts = parts.map((part) => {
    return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase();
  });

  // Join the title case parts with a space
  return titleCaseParts.join(' ');
};

const blobToDataURI = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getPDFDataURI = async (url) => {
  const response = await usePdfFetch(url);
  const blob = await response.blob();
  return await blobToDataURI(blob);
};

// opens a new print window
export const triggerFilePrint = (content, mimeType, t) => {
  const blob = new Blob([content], { type: mimeType });
  const url = window?.URL.createObjectURL(blob);
  const printWindow = window?.open(url);

  if (printWindow) {
    printWindow.onload = () => {
      printWindow.print();
      URL.revokeObjectURL(url);
    };
  } else {
    alert(t('utils.functions.blockedPrintWindow'));
  }
};

export const triggerFileDownload = (content, fileName, mimeType) => {
  const blob = new Blob([content], { type: mimeType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Formats a number into a more readable string using suffixes for large numbers.
 *
 * @param {number} num - The number to format.
 * @param {number} [digits=1] - The number of decimal places to include in the formatted number.
 * @returns {string} The formatted number with an appropriate suffix.
 */
export const nFormatter = (num, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.findLast((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, '').concat(item.symbol)
    : '0';
};

/**
 * Filters an array of objects to include only those whose IDs are present in a given array of IDs.
 *
 * @param {Array<string>} ids - An array of IDs to filter by.
 * @param {Array<Object>} objects - A large array of objects with a schema {id: '', name: '', ...}.
 * @returns {Array<Object>} A filtered array of objects containing only those with IDs present in the ids array.
 */
export const filterByIds = (ids, objects) => {
  // Create a Set from the ids array for quick lookup
  const idSet = new Set(ids);

  // Filter the objects array to include only objects whose id is in the idSet
  return objects.filter((object) => idSet.has(object.id));
};

/**
 * Converts a given number of bytes into a more human-readable format.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @param {number} [decimals=2] - The number of decimal places to include in the formatted output.
 * @returns {string} A string representing the formatted bytes in a human-readable format with appropriate units.
 *
 * @example
 *
 * formatBytes(1024);
 * // => "1 KB"
 *
 * formatBytes(1234, 3);
 * // => "1.205 KB"
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Sorts an array of objects by a specified date field.
 * @param {Array} data - The array of objects to be sorted.
 * @param {string} dateField - The field containing the date to sort by.
 * @returns {Array} - The sorted array of objects.
 */
export const sortByDateField = (data, dateField) => {
  return [...data].sort((a, b) => {
    const dateA = a[dateField];
    const dateB = b[dateField];

    if (dateA && dateB) {
      return new Date(dateB) - new Date(dateA);
    } else if (dateA) {
      return -1;
    } else if (dateB) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const convertDateFormat = (time, timezone = 'local') => {
  let parsedTime;
  let timezoneAbbr;

  if (timezone === 'UTC') {
    parsedTime = moment.utc(time, INPUT_FORMAT);
    timezoneAbbr = 'UTC';
  } else if (timezone === 'CST') {
    parsedTime = moment.utc(time, INPUT_FORMAT).utcOffset(-6, false);
    timezoneAbbr = 'CST';
  } else {
    parsedTime = moment.utc(time, INPUT_FORMAT).local();
    timezoneAbbr = '';
  }

  const formattedTime = parsedTime.format('ddd, D MMM YYYY HH:mm');
  const timestamp = parsedTime.fromNow();

  return `${formattedTime} (${timestamp}) ${timezoneAbbr}`;
};

/**
 * Converts a value to an array of strings.
 *
 * If the value is already an array, it filters out any items
 * with an empty string value.
 * If the value is a string, it splits the string by commas and
 * trims any whitespace from the resulting items.
 * If the value is neither an array nor a string, it returns the value as is.
 *
 * @param {Array|string} value - The value to convert to an array of strings.
 * @returns {Array} - An array of strings.
 */
export const stringsToArray = (value) => {
  if (Array.isArray(value)) {
    return value.filter((item) => item.value !== '');
  }

  if (typeof value === 'string') {
    return value.split(',').map((item) => item.trim());
  }
  return value;
};

export const validateContainerName = (value, t) => {
  if (!value) {
    return '';
  }
  const containerNamePattern = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;
  if (!containerNamePattern.test(value)) {
    return t(
      'fileUploaderPage.storageConfiguration.newSourcePopover.containerNameText.validatePattern',
    );
  }
  if (value.length < 3 || value.length > 63) {
    return t(
      'fileUploaderPage.storageConfiguration.newSourcePopover.containerNameText.validateLength',
    );
  }
  return '';
};

/**
 * Extracts the file extension from a given URL.
 *
 * This function uses a regular expression to find and return the file extension
 * from the specified URL. It handles URLs with query parameters and fragments by
 * only considering the part of the URL before any `?` or `#` characters.
 *
 * @param {string} url - The URL from which to extract the file extension.
 * @returns {string|null} - The file extension if found, otherwise `null`.
 *
 * @example
 * // Returns "jpg"
 * getFileExtension("https://example.com/image.jpg?signature=abc123&expires=1600000000");
 *
 * @example
 * // Returns "html"
 * getFileExtension("https://example.com/path/to/page.html#section");
 *
 * @example
 * // Returns null (no extension found)
 * getFileExtension("https://example.com/folder/");
 */
export const getFileExtension = (url) => {
  // Define a regular expression to capture the file extension
  // - `(?:\.`: Non-capturing group to match a dot character
  // - `([^.]+)`: Capturing group to match one or more characters that are not a dot
  // - `)?`: The capturing group is optional (it may or may not be present)
  // - `(?:\?|$)`: Non-capturing group to match either a question mark (query parameter) or end of string
  const regex = /(?:\.([^.]+))?(?:\?|$)/;

  // Execute the regular expression on the provided URL
  const matches = regex.exec(url);

  // Return the captured extension from the matches, or `null` if no match is found
  return matches ? matches[1] : null;
};

/**
 * Compares two values for deep equality, including nested objects and arrays.
 * @param {any} obj1 - The first value to compare.
 * @param {any} obj2 - The second value to compare.
 * @returns {boolean} - Returns true if the values are deeply equal, false otherwise.
 */
export const deepCompare = (obj1, obj2) => {
  // Check if both values are of the same type
  if (typeof obj1 !== typeof obj2) return false;

  // If values are not objects (e.g., primitives like number, string), compare directly
  if (typeof obj1 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  // Handle Array comparison
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    // Recursively compare each element in both arrays
    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i])) return false;
    }
    return true;
  }

  // If one is an array and the other is not, return false
  if (Array.isArray(obj1) !== Array.isArray(obj2)) return false;

  // Handle Object comparison
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      // Safely check if obj2 has the same property using Object.prototype.hasOwnProperty
      if (!Object.prototype.hasOwnProperty.call(obj2, key)) return false;
      if (!deepCompare(obj1[key], obj2[key])) return false; // Recursively compare key values
    }
    return true;
  }

  return false;
};

/**
 * Converts an array of objects with 'key' and 'value' properties to a single object.
 *
 * @param {Array} arr - The array of objects to convert, where each object has a 'key' and 'value'.
 * @returns {Object} The resulting object where keys are from the 'key' field and values are from the 'value' field.
 *
 * @example
 * const arr = [{ key: 'name', value: 'John' }, { key: 'age', value: 30 }];
 * const obj = arrayToObject(arr);
 * // obj: { name: 'John', age: 30 }
 */
export const arrayToObject = (arr) => {
  return arr.reduce((acc, item) => {
    if (item.key && typeof item.value !== 'undefined') {
      acc[item.key] = item.value;
    }
    return acc;
  }, {});
};

/**
 * Converts an object into an array of objects with 'key' and 'value' properties.
 *
 * @param {Object} obj - The object to convert.
 * @returns {Array} An array where each element is an object with 'key' and 'value' fields.
 *
 * @example
 * const obj = { name: 'John', age: 30 };
 * const arr = objectToArray(obj);
 * // arr: [{ key: 'name', value: 'John' }, { key: 'age', value: 30 }]
 */
export const objectToArray = (obj) => {
  return Object.keys(obj).map((key) => ({
    key: key,
    value: obj[key],
  }));
};

export const generateUniqueFileCollectionName = (userName) => {
  if (userName == undefined) return '';
  const firstName = userName.split(' ')[0];
  const now = new Date();
  const formattedTimestamp = [
    now.getFullYear().toString().slice(-2),
    String(now.getMonth() + 1).padStart(2, '0'),
    String(now.getDate()).padStart(2, '0'),
    String(now.getHours()).padStart(2, '0'),
    String(now.getMinutes()).padStart(2, '0'),
  ].join('');
  return `${firstName}${formattedTimestamp}`;
};
