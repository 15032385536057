/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.query({
    query: ({ collectionId, include, exclude }) => {
      const params = new URLSearchParams();

      params.append('include', include?.join(','));
      params.append('exclude', exclude?.join(','));

      return {
        url: `file-collections/${collectionId}?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['individualFileCollections'],
    invalidateTags: ['fileCollections'],
  });
