/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Grid,
  Box,
  LinearProgress,
  Typography,
  Container,
  Tooltip,
  CircularProgress,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import { Cancel, CloudDone, Launch, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import SearchSuggestion from './searchSuggestion';
import {
  ShadowBox,
  AnswerComponentWrapper,
  LoadingTypography,
  ResultBox,
  FoundDataAlert,
  ResultTitle,
  HoveringLink,
  MetaDataGridBox,
  InfographicSVG,
  FilterBox,
  UploadFileBox,
  FilterTableBox,
  FileUploadIcon,
  MetaGrid,
  UploadFilesTitleConatiner,
  UploadFilesSubTitleConatiner,
  FileNameBox,
  FileSizeText,
  FilterFooterBox,
  StatusText,
  InfographicText,
} from './search.page.styled';
import { SearchbarBase } from '../../components/searchbar';
import { Answer, AnswerWithPdf } from '../../components/answer';
import PageContainer from '../../components/pageContainer';
import {
  useGetResultsMutation,
  useAddFeedbackMutation,
  useLazyGetFaqQuery,
  useLazyGetMetaDataPropertiesQuery,
  useLazyGetIndividualFileCollectionQuery,
  useAddMetaDataFiltersMutation,
  useAddConversationMutation,
  useLazyGetIngestedFilesQuery,
  useIngestFileMutation,
  useGetIngestFilePermissionMutation,
  useDeleteIndividualFileMutation,
} from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import isMetadataFunctionalityEnabledInApp from '../../constants/metadata';
import isSearchFilterEnabled from '../../constants/searchFilter';
import * as PATHS from '../../constants/path';
import { EXTRACTIVE_SEARCH_ENGINE } from '../../constants/searchEngines';
import { FileCollectionContext } from '../../hooks/useContext';
import BooleanField from '../../components/booleanField';
import MetaDataGrid from '../../components/metadataGrid';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import isConversationHistoryEnabled from '../../constants/conversationHistory';
import colors from '../../themes/colors';
import DataGridTable from '../../components/datagrid/datagrid.component';
import useDebounce from '../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../constants/debounce';
import { filterByIds, formatBytes } from '../../utils/functions';
import { ALLOWED_FILE_SIZE, ALLOWED_FILE_TYPES } from '../../constants/files';
import { emailRegEx } from '../../utils/regex';

const params = (languageCode, searchQuery, collectionId) => {
  return { q: searchQuery, lang: languageCode, cid: collectionId };
};

const SearchPage = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidResult, setIsValidResult] = useState(false);
  const [inputTextForAlert, setInputTextForAlert] = useState('');
  const [faqList, setFaqList] = useState([]);
  const [languageCode, setLanguageCode] = useState('');
  const [showAnswerContainer, setShowAnswerContainer] = useState(true);
  const [suggestiveSearch, setSuggestiveSearch] = useState(false);
  const [isQueryMetaData, setIsQueryMetaData] = useState(true);
  const [isGetMetaDataGrid, setIsMetaDataGrid] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize] = useState(20);
  const [gridSize, setGridSize] = useState(3);
  const {
    getCollectionId,
    isFileCollectionChanged,
    getSearchEngine,
    updateCollectionId,
    getSelectedFileCollection,
  } = useContext(FileCollectionContext);
  const { getSiteConfig } = useContext(SiteConfigContext);
  const [clearScreen, setClearScreen] = useState(false);
  const [infographics, setInfographics] = useState({
    type: 'text',
    file: '',
    text: '',
  });
  const [currentTime, setCurrentTime] = useState('');

  const [filterClick, setFilterClick] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [filterTableRowsData, setFilterTableRowsData] = useState([]);
  const [filterTableSearchQuery, setFilterTableSearchQuery] = useState('');
  const [ingestFilePermission, setIngestFilePermission] = useState('deny');
  const [generatingMessage, setGeneratingMessage] = useState(
    t('trainedquestionAnswerPage.loadingMessage'),
  );
  const [deletingFile, setDeletingFile] = useState('');
  const isColumnVisible = useMediaQuery('(max-width:740px)');
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
  };

  const sortBySelectedIds = (data, selectedIds) => {
    const selectedSet = new Set(selectedIds);
    return [...data].sort((a, b) => {
      const aSelected = selectedSet.has(a.id);
      const bSelected = selectedSet.has(b.id);
      return bSelected - aSelected; // True (1) comes before False (0)
    });
  };

  const getColumns = () => {
    let mockColumns = [
      {
        field: 'id',
        headerName: (
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
                indeterminate={!selectAll && selectedIds.length > 0}
              />
            }
          />
        ),
        sortable: false,
        filterable: false,
        flex: 0.2,
        renderCell: (params) => (
          <Checkbox
            checked={selectAll || selectedIds.includes(params.id)}
            onChange={() => {
              let tempSelectedIds = [];
              if (selectedIds.includes(params.id)) {
                tempSelectedIds = selectedIds.filter((id) => id !== params.id);
              } else {
                tempSelectedIds = [...selectedIds, params.id];
              }

              setSelectedIds(tempSelectedIds);
              setFilterTableRowsData(
                sortBySelectedIds(filterTableRowsData, tempSelectedIds),
              );
            }}
          />
        ),
      },
      {
        field: 'fileName',
        headerName: 'File Name',
        minWidth: 50,
        flex: 1,
        renderCell: (params) => {
          const fileName = params.value;
          const sizeInBytes = params.row.size;
          const formattedSize = formatBytes(sizeInBytes);

          return (
            <FileNameBox>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {fileName}
              </span>
              {isColumnVisible && <FileSizeText>{formattedSize}</FileSizeText>}
            </FileNameBox>
          );
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        align: 'center',
        sortable: false,
        width: 70,
        renderCell: (params) => {
          const status = params.value;
          let icon, color, tooltip;

          switch (status) {
            case 'completed':
              icon = <CloudDone />;
              color = colors.green.G400;
              tooltip = 'Ingested';
              break;

            case 'uploading':
              icon = <CircularProgress size={20} />;
              color = colors.neutralLight.N200;
              tooltip = 'Uploading';
              break;

            case 'rejected':
              icon = <Cancel />;
              color = colors.red.R400;
              tooltip = 'Something went wrong';
              break;

            default:
              icon = null;
              break;
          }

          return (
            <Tooltip title={tooltip || ''}>
              <span style={{ color, display: 'flex', alignItems: 'center' }}>
                {icon}
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        width: 100,
        renderCell: (params) => {
          const sizeInBytes = params.row.size;
          const formattedSize = formatBytes(sizeInBytes);
          return <div>{formattedSize}</div>;
        },
      },
      {
        headerName: 'Delete',
        sortable: false,
        headerAlign: 'center',
        width: 70,
        align: 'center',
        renderCell: (props) => {
          const fileName = props?.row?.fileName;
          return (
            <>
              <LoadingButton
                color="primary"
                variant="text"
                loading={isDeleteFileLoading && fileName == deletingFile}
                onClick={(e) => handleDeleteFile(e, props)}
                disabled={isIngesting || isDeleteFileLoading}
              >
                <Delete />
              </LoadingButton>
            </>
          );
        },
      },
    ];
    if (isColumnVisible) {
      return mockColumns.filter((col) => col.field !== 'size');
    }
    return mockColumns;
  };

  const [
    getIngestedFiles,
    {
      data: ingestedFilesObj,
      error: ingestedFilesError,
      isFetching: isGetIngestedFilesFetching,
      isSuccess: isGetIngestedFilesSuccess,
    },
  ] = useLazyGetIngestedFilesQuery();

  const [
    getPermissionForIngestedFiles,
    {
      data: ingestedFilesPermission = [],
      error: ingestedFilesPermissionError,
      isFetching: isGetIngestedFilesPermissionFetching,
      isSuccess: isGetIngestedFilesPermissionSuccess,
    },
  ] = useGetIngestFilePermissionMutation();

  const [
    deleteIndividualFile,
    {
      data: deleteFile,
      isSuccess: isDeleteFileSuccess,
      isLoading: isDeleteFileLoading,
      isError: isDeleteFileError,
    },
  ] = useDeleteIndividualFileMutation();

  useEffect(() => {
    let fCId = getCollectionId();
    if (fCId) {
      getPermissionForIngestedFiles({
        actions: ['FileCollection:IngestFiles', 'FileCollection:View'],
        resourceIds: [fCId],
      });
    }
  }, []);

  useEffect(() => {
    let fCId = getCollectionId();
    if (fCId && isSearchFilterEnabled) {
      getPermissionForIngestedFiles({
        actions: ['FileCollection:IngestFiles', 'FileCollection:View'],
        resourceIds: [fCId],
      });
    }
  }, [getCollectionId()]);

  useEffect(() => {
    if (ingestedFilesPermission && isGetIngestedFilesPermissionSuccess) {
      let resourceId = getCollectionId();
      let permission = 'FileCollection:IngestFiles';
      setIngestFilePermission(
        ingestedFilesPermission?.[resourceId]?.[permission],
      );
    }
  }, [ingestedFilesPermission, isGetIngestedFilesPermissionSuccess]);

  const [
    ingestFile,
    {
      data: ingestFileResponse,
      error: ingestFileError,
      isLoading: isIngesting,
    },
  ] = useIngestFileMutation();

  const getIngestedFilesFromData = (data) => {
    const ingestedFiles = [];

    data?.ingestedFiles?.forEach((ingf) => {
      ingestedFiles.push({
        id: ingf.id,
        fileName: ingf.fileName,
        status: ingf.status === 'SUCCESS' ? 'completed' : 'rejected',
        size: ingf.size,
      });
    });

    return ingestedFiles;
  };

  const handleLoadMoreFiles = async () => {
    const newPageNo = pageNo + 1;

    setLoadingMore(true);
    const data = await getIngestedFiles({
      collectionId: getCollectionId(),
      pageNo: newPageNo,
      pageSize,
    }).unwrap();
    setLoadingMore(false);

    const ingestedFiles = getIngestedFilesFromData(data);

    setPageNo(newPageNo);
    setFilterTableRowsData([...filterTableRowsData, ...ingestedFiles]);
  };

  useDebounce(
    async () => {
      const collectionId = getCollectionId();

      if (collectionId && isSearchFilterEnabled) {
        const data = await getIngestedFiles({
          collectionId,
          pageNo: 1,
          pageSize,
          query: filterTableSearchQuery,
        }).unwrap();

        const ingestedFiles = getIngestedFilesFromData(data);

        filterTableRowsData
          .filter((ft) => selectedIds.includes(ft.id))
          .forEach((ft) => {
            if (!ingestedFiles.map((ingf) => ingf.id).includes(ft.id))
              ingestedFiles.push(ft);
          });

        if (filterTableSearchQuery) setFilterTableRowsData(ingestedFiles);
        else
          setFilterTableRowsData(sortBySelectedIds(ingestedFiles, selectedIds));
      }
    },
    [filterTableSearchQuery, getCollectionId()],
    DEBOUNCE_DELAY,
  );

  const getUniqueAcceptedFiles = (acceptedFiles, stateData) => {
    const existingFileNames = new Set(stateData.map((row) => row.fileName));

    const uniqueFiles = [];
    const seenFileNames = new Set();

    acceptedFiles.forEach((file) => {
      if (!existingFileNames.has(file.name) && !seenFileNames.has(file.name)) {
        uniqueFiles.push(file);
        seenFileNames.add(file.name); // Mark this file name as seen
      }
    });

    return uniqueFiles;
  };

  const onDrop = useCallback(
    async (accepFiles) => {
      const acceptedFiles = getUniqueAcceptedFiles(
        accepFiles,
        filterTableRowsData,
      );

      const newFiles = [];

      acceptedFiles.forEach((af) =>
        newFiles.push({
          id: af?.name,
          fileName: af?.name,
          status: 'uploading',
        }),
      );

      const tempRows = [...newFiles, ...filterTableRowsData];

      setFilterTableRowsData(tempRows);

      const uploadPromises = acceptedFiles.map((accepFile) =>
        ingestFile({
          collectionId: getCollectionId(),
          file: accepFile,
        })
          .unwrap()
          .then((ingestFileRes) => ({ ingestFileRes, accepFile }))
          .catch((error) => ({ error, accepFile })),
      );

      const results = await Promise.allSettled(uploadPromises);

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { ingestFileRes, accepFile } = result.value;
          const index = tempRows
            .map((tr) => tr.fileName)
            .indexOf(accepFile.name);
          if (index !== -1) {
            tempRows[index].status =
              ingestFileRes?.status === 'SUCCESS' ? 'completed' : 'rejected';
          }
        } else {
          const { accepFile } = result.reason;
          const index = tempRows
            .map((tr) => tr.fileName)
            .indexOf(accepFile?.name);
          if (index !== -1) {
            tempRows[index].status = 'rejected';
          }
        }
      });

      setFilterTableRowsData([...tempRows]);
    },
    [filterTableRowsData],
  );

  useEffect(() => {
    if (isGetIngestedFilesSuccess && ingestedFilesObj?.ingestedFiles) {
      const updatedRows = ingestedFilesObj.ingestedFiles.map((ingf) => ({
        id: ingf.id,
        fileName: ingf.fileName,
        status: ingf.status === 'SUCCESS' ? 'completed' : 'rejected',
        size: ingf.size,
      }));

      setFilterTableRowsData(updatedRows);
    }
  }, [ingestedFilesObj, isGetIngestedFilesSuccess]);

  const handleDeleteFile = (e, props) => {
    e.stopPropagation();
    const fileName = props?.row?.fileName;
    setDeletingFile(fileName);
    const prevRows = [...filterTableRowsData];
    setFilterTableRowsData((prev) =>
      prev.filter((file) => file.fileName !== fileName),
    );
    try {
      deleteIndividualFile({
        collectionId: getCollectionId(),
        fileName: fileName,
      });
    } catch (error) {
      setFilterTableRowsData(prevRows);
    }
  };

  const isRowSelectable = (params) => {
    return params?.row?.status !== 'rejected';
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ALLOWED_FILE_TYPES,
    maxSize: ALLOWED_FILE_SIZE,
  });

  const getFilteredFilesToAsk = () => {
    const successFilterTableRows = filterTableRowsData.filter(
      (file) => file.status === 'completed',
    );
    return filterByIds(selectedIds, successFilterTableRows).map(
      (f) => f.fileName,
    );
  };

  const displayMessageWhileSearching = () => {
    const fileNames = getFilteredFilesToAsk();

    if (fileNames.length === 0) return '';

    return `${fileNames.length} files`;
  };

  useEffect(() => {
    const siteConfig = getSiteConfig();

    setInfographics({
      type: siteConfig?.infographicsType || 'svg',
      file: siteConfig?.infographicsFile || '',
      text: siteConfig?.infographicsText || '',
    });
  }, [getSiteConfig()]);

  const [
    getFaq,
    {
      data: faqs = [],
      isError: isGetFaqError,
      isLoading: isGetFaqLoading,
      isSuccess: isGetFaqSuccess,
      isFetching: isGetFaqFetching,
    },
  ] = useLazyGetFaqQuery();

  useEffect(() => {
    setFaqList(faqs);
  }, [isGetFaqFetching]);

  const [
    addFeedback,
    {
      data: feedbackData = '',
      isError: isAddFeedbackError,
      isLoading: isAddFeedbackLoading,
      isSuccess: isAddFeedbackSuccess,
    },
  ] = useAddFeedbackMutation();

  const [
    findAnswers,
    {
      data: result,
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useGetResultsMutation();

  const [
    addConversation,
    {
      data: conversation = [],
      isSuccess: isGetConversationSuccess,
      isLoading: isGetConversationLoading,
      isError: isGetConversationError,
    },
  ] = useAddConversationMutation();

  const [
    findMetaData,
    {
      data: response = [],
      isSuccess: isGetMetaDataSuccess,
      isLoading: isGetMetaDataLoading,
      isError: isGetMetaDataError,
    },
  ] = useAddMetaDataFiltersMutation();

  const [
    metadataProperties,
    {
      data: definedMetaProperties = {},
      isError: isGetMetaPropertiesError,
      isLoading: isGetMetaPropertiesLoading,
      isSuccess: isGetMetaPropertiesSuccess,
    },
  ] = useLazyGetMetaDataPropertiesQuery();

  const [
    getFileCollection,
    {
      data: individualFileCollection,
      isError: isGetIndividualFileCollectionError,
      isLoading: isGetIndividualFileCollectionLoading,
      isSuccess: isGetIndividualFileCollectionSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  const freshInput = () => {
    const collectionId = getCollectionId();

    if (!collectionId) return;

    getFaq({
      collectionId,
    });
    getFileCollection({
      collectionId,
    });
    setInput('');
    setErrorMessage('');
    setIsValidResult(false);
    setInputTextForAlert('');
    setShowAnswerContainer(true);
    setSuggestiveSearch(false);
    setIsQueryMetaData(true);
    setIsMetaDataGrid(true);
    setPageNo(1);
    setGridSize(3);
  };

  useEffect(() => {
    if (result) {
      setGeneratingMessage(t('trainedquestionAnswerPage.loadingMessage'));

      if (result?.hasError) {
        setIsValidResult(false);
        setErrorMessage(result.message);
      } else {
        const time = moment();
        setIsValidResult(true);
        setCurrentTime(time.format('YYYY-MM-DDTHH:mm:ssZ'));
      }
      setClearScreen(false);
    }
  }, [isGetResultsSuccess, result]);

  useEffect(() => {
    if (isAddFeedbackSuccess) {
      dispatch(
        pushToast({
          message: t('speciphicAskPage.feedbackSuccess'),
          severity: 'success',
        }),
      );
    }
  }, [isAddFeedbackSuccess]);

  useEffect(() => {
    if (isGetResultsError) {
      setIsValidResult(false);
      setErrorMessage(t('speciphicAskPage.errorResult'));

      dispatch(
        pushToast({
          message: t('speciphicAskPage.errorResult'),
          severity: 'error',
        }),
      );
    }
  }, [isGetResultsError]);

  const handleLanguageChange = (value) => {
    setLanguageCode(value.code);
  };

  const handleClickFetchAnswer = (collectionId, query, languageCode) => {
    if (
      isMetadataFunctionalityEnabledInApp &&
      individualFileCollection?.isMetadataEnabled
    ) {
      metadataProperties({
        collectionId,
      });
    }

    if (!selectAll && filterClick)
      setGeneratingMessage(
        `Generating answer from ${displayMessageWhileSearching()} ...`,
      );

    findAnswers({
      collectionId,
      query,
      acceptLanguage: languageCode,
      includeMetadata:
        isMetadataFunctionalityEnabledInApp &&
        individualFileCollection?.isMetadataEnabled &&
        isQueryMetaData,
      filters:
        !selectAll && filterClick && getFilteredFilesToAsk().length > 0
          ? {
              fileName: getFilteredFilesToAsk(),
            }
          : {},
    });

    setFilterClick(false);
    setInputTextForAlert(query);
    setClearScreen(true);
    setShowAnswerContainer(false);
    navigate({
      pathname: `${PATHS.HOMEPAGE}`,
      search: `${createSearchParams(
        params(languageCode, query, collectionId),
      )}`,
    });
    if (
      isMetadataFunctionalityEnabledInApp &&
      isGetMetaDataGrid &&
      individualFileCollection?.isMetadataEnabled
    ) {
      findMetaData({
        collectionId,
        query,
        size: gridSize,
        pageNo: pageNo,
      });
    }
  };

  useEffect(() => {
    if (suggestiveSearch) {
      handleClickFetchAnswer(getCollectionId(), input, languageCode);
      setSuggestiveSearch(false);
    }
  }, [suggestiveSearch, getCollectionId()]);

  // this useEffect for auto fetch answer for url
  useEffect(() => {
    if (!location.search) {
      setShowAnswerContainer(true);
    }

    if (search.get('cid')) {
      const query = search.get('q');
      const languageCode = search.get('lang');
      const collectionId = search.get('cid');

      setInput(query);
      setLanguageCode(languageCode);

      updateCollectionId(collectionId);

      handleClickFetchAnswer(collectionId, query, languageCode);
    } else freshInput();
  }, []);

  useEffect(() => {
    freshInput();

    if (search.get('q')) {
      navigate({
        pathname: `${PATHS.HOMEPAGE}`,
      });
    }
  }, [isFileCollectionChanged]);

  const handleClickFeedback = (res, bool) => {
    {
      isGetResultsSuccess &&
        addFeedback({
          chatId: result.id,
          isHelpful: bool,
        });
    }
  };

  const handleMetaDataButton = () => {
    const collectionId = getCollectionId();

    navigate(`${PATHS.SEARCH_METADATA_PAGE}/${collectionId}`, {
      state: {
        collectionId: collectionId,
      },
    });
  };

  const handleMetaDataGrid = (value) => {
    setIsMetaDataGrid(value);
  };

  const handleNavigateConversationPath = () => {
    addConversation({
      collectionId: getCollectionId(),
      query: null,
      acceptLanguage: 'en-US',
      includeMetadata: true,
      conversationId: null,
      chatHistoryId: result?.id,
    });
  };

  useEffect(() => {
    const collectionId = getCollectionId();

    if (isGetConversationSuccess) {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.CONVERSATION}/${conversation[0].conversation_id}`,
        {
          state: {
            fileCollectionId: collectionId,
            conversationId: conversation[0].conversation_id,
            isConversationHistorySent: true,
            time: currentTime,
          },
        },
      );
    }
  }, [isGetConversationSuccess]);

  const getIsDatabaseSource = () => {
    return getSelectedFileCollection()?.isStorageSource;
  };

  const formatResponse = (text) => {
    if (!text) return '';
    return text.replace(emailRegEx, '<$1>');
  };

  return (
    <>
      <PageContainer>
        <>
          {infographics?.type === 'svg' ? (
            <InfographicSVG src={infographics?.file} alt="Infographics" />
          ) : (
            <>
              <InfographicText variant="h1">
                {infographics?.text || ''}
              </InfographicText>
            </>
          )}
        </>

        <Container sx={{ width: '100%', paddingX: { xs: 1, sm: 8 } }}>
          <>
            <ShadowBox>
              <Grid container>
                <SearchbarBase
                  onSearchClick={() =>
                    handleClickFetchAnswer(
                      getCollectionId(),
                      input,
                      languageCode,
                    )
                  }
                  onFilterClick={() => setFilterClick(!filterClick)}
                  value={input}
                  setQueryLanguage={handleLanguageChange}
                  onChange={setInput}
                  placeholder={t(
                    'trainedquestionAnswerPage.searchbarBase.placeholder',
                  )}
                  showFilter={!getIsDatabaseSource() && isSearchFilterEnabled}
                />

                {filterClick &&
                  getCollectionId() != undefined &&
                  isSearchFilterEnabled && (
                    <FilterBox>
                      {ingestFilePermission == 'allow' && (
                        <UploadFileBox {...getRootProps()}>
                          <input {...getInputProps()} />
                          <UploadFilesTitleConatiner>
                            <FileUploadIcon />
                            {isDragActive
                              ? t(
                                  'trainedquestionAnswerPage.fileUploadButton.isDragActiveText',
                                )
                              : t(
                                  'trainedquestionAnswerPage.fileUploadButton.uploadTitle',
                                )}
                          </UploadFilesTitleConatiner>

                          <UploadFilesSubTitleConatiner>
                            {t(
                              'trainedquestionAnswerPage.fileUploadButton.uploadDescription',
                            )}
                          </UploadFilesSubTitleConatiner>
                        </UploadFileBox>
                      )}

                      <FilterTableBox permission={ingestFilePermission}>
                        <DataGridTable
                          rowId="id"
                          row={filterTableRowsData}
                          column={getColumns()}
                          onSearch={(e) => {
                            setSelectAll(false);
                            setFilterTableSearchQuery(e);
                          }}
                          serverSideSearch={true}
                          isSearching={isGetIngestedFilesFetching}
                          selectedCount={
                            selectAll
                              ? ingestedFilesObj?.total
                              : selectedIds.length
                          }
                          total={ingestedFilesObj?.total?.toString() || '0'}
                          hideFooter={
                            filterTableSearchQuery ||
                            filterTableRowsData.length ===
                              ingestedFilesObj?.total
                          }
                          FooterComponent={() => (
                            <FilterFooterBox>
                              <StatusText
                                variant="body1"
                                color="primary"
                              >{`Showing ${filterTableRowsData.length} of ${ingestedFilesObj?.total}`}</StatusText>
                              <LoadingButton
                                variant="outlined"
                                color="primary"
                                onClick={handleLoadMoreFiles}
                                loading={loadingMore}
                              >
                                Load More
                              </LoadingButton>
                            </FilterFooterBox>
                          )}
                          showSelectedCount
                          showTotalCount={false}
                          isRowSelectable={isRowSelectable}
                          searchPlaceholder="Search Files..."
                          autoHeight={false}
                        />
                      </FilterTableBox>
                    </FilterBox>
                  )}
                {isGetIndividualFileCollectionSuccess &&
                  !individualFileCollection?.isStoreCreated && (
                    <Alert
                      severity="error"
                      sx={{
                        marginTop: '8px',
                      }}
                    >
                      {t('trainedquestionAnswerPage.alertMessages.noFiles')}
                    </Alert>
                  )}

                {!filterClick &&
                  isMetadataFunctionalityEnabledInApp &&
                  individualFileCollection?.isMetadataEnabled &&
                  getCollectionId() && (
                    <Grid container item display="flex" xs={12}>
                      <Grid
                        container
                        item
                        xs={8}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ paddingLeft: 4 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          display="flex"
                          justifyContent="flex-start"
                          sx={{ py: 2 }}
                        >
                          <BooleanField
                            value={isQueryMetaData}
                            label={t(
                              'speciphicAskPage.metadataFields.includeMetadataLabel',
                            )}
                            onChange={(value) => setIsQueryMetaData(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </Grid>
                        <MetaGrid item xs={12} sm={5}>
                          <BooleanField
                            value={isGetMetaDataGrid}
                            label={t(
                              'speciphicAskPage.metadataFields.showMetadataMatchesLabel',
                            )}
                            onChange={(value) => handleMetaDataGrid(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </MetaGrid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <HoveringLink
                          aria-label="search"
                          onClick={handleMetaDataButton}
                        >
                          {t(
                            'speciphicAskPage.metadataFields.goToMetadataSearch',
                          )}
                          <Launch sx={{ fontSize: 16, marginLeft: 0.5 }} />
                        </HoveringLink>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </ShadowBox>

            {!filterClick &&
              showAnswerContainer &&
              getCollectionId() != undefined && (
                <SearchSuggestion
                  isLoading={isGetFaqFetching}
                  faqList={faqList}
                  setInput={setInput}
                  suggestiveTrue={setSuggestiveSearch}
                />
              )}
          </>

          {!showAnswerContainer ? (
            <ResultBox>
              {isGetResultsLoading ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <LinearProgress sx={{ width: '40%' }} />
                  <LoadingTypography
                    variant="h5"
                    paddingTop={1}
                    color="primary"
                  >
                    {generatingMessage}
                  </LoadingTypography>
                </Box>
              ) : (
                <>
                  {result?.hasError ? (
                    <Alert
                      severity="error"
                      sx={{
                        borderRadius: 0,
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  ) : (
                    <>
                      {isValidResult && (
                        <>
                          <FoundDataAlert
                            severity="success"
                            sx={{
                              '.MuiSvgIcon-root': { color: '#FFFFFF' },
                              borderRadius: 0,
                            }}
                          >
                            {t(
                              'trainedquestionAnswerPage.alertMessages.foundData',
                              {
                                searchText: inputTextForAlert,
                              },
                            )}
                          </FoundDataAlert>
                        </>
                      )}
                      {isValidResult && (
                        <AnswerComponentWrapper>
                          <ResultTitle>
                            {t('trainedquestionAnswerPage.resultHeader')}

                            {isConversationHistoryEnabled &&
                              !getIsDatabaseSource() && (
                                <LoadingButton
                                  variant="contained"
                                  startIcon={<ChatIcon />}
                                  size="small"
                                  onClick={handleNavigateConversationPath}
                                  loading={isGetConversationLoading}
                                >
                                  {t('speciphicAskPage.followUpQuestion')}
                                </LoadingButton>
                              )}
                          </ResultTitle>

                          {getSearchEngine() === EXTRACTIVE_SEARCH_ENGINE ? (
                            <Answer
                              title={formatResponse(result?.answer) || ''}
                              description={result?.context || ''}
                              meta={result?.originalFileName || ''}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              result={result}
                              query={input}
                            />
                          ) : (
                            <AnswerWithPdf
                              result={result}
                              title={formatResponse(result?.answer) || ''}
                              description={
                                formatResponse(result?.context) || ''
                              }
                              metas={result?.meta || []}
                              pdfPreview={true}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              query={input}
                              collectionId={getCollectionId()}
                              isDefaultFeedbackRequired={true}
                            />
                          )}
                        </AnswerComponentWrapper>
                      )}
                    </>
                  )}
                </>
              )}
            </ResultBox>
          ) : null}
          {!showAnswerContainer && !isGetResultsLoading && (
            <ResultBox>
              <>
                {isMetadataFunctionalityEnabledInApp &&
                  isGetMetaDataGrid &&
                  isGetMetaDataSuccess &&
                  result &&
                  response?.results?.length > 0 &&
                  !clearScreen && (
                    <MetaDataGridBox>
                      <Grid>
                        <Box sx={{ paddingTop: 2 }}>
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: 'bold', fontSize: 20 }}
                          >
                            {t(
                              'speciphicAskPage.metadataFields.metadataGrid.filesRelatedToSearch',
                            )}
                          </Typography>
                        </Box>

                        <MetaDataGrid
                          files={response?.results || []}
                          definedMetaProperties={definedMetaProperties}
                          pageSize={gridSize}
                          onPageSizeChange={(newPageSize) =>
                            setGridSize(newPageSize)
                          }
                          pageNo={pageNo}
                          onPageNoChange={(newPageNo) => setPageNo(newPageNo)}
                          totalPages={`${response?.total_size}`}
                          isMainSearchPage={true}
                          noRowsMessage={t(
                            'speciphicAskPage.metadataFields.metadataGrid.noRowsMessage',
                          )}
                        />
                      </Grid>
                    </MetaDataGridBox>
                  )}
              </>
            </ResultBox>
          )}
        </Container>
      </PageContainer>
    </>
  );
};

SearchPage.propTypes = {
  row: PropTypes.object,
};

export default SearchPage;
