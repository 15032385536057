/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({
      collectionId,
      query,
      acceptLanguage,
      includeMetadata,
      filters = {},
    }) => ({
      url: includeMetadata
        ? `file-collections/${collectionId}/search?q=${encodeURIComponent(
            query,
          )}&metadata=${includeMetadata}`
        : `file-collections/${collectionId}/search?q=${encodeURIComponent(
            query,
          )}`,
      method: 'POST',
      body: {
        acceptLanguage: acceptLanguage,
        filters,
      },
    }),
  });
