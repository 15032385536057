// Generate nodes from a dynamic data schema.
// Each node uses the table key as the id, displays the table_name as the label,
// and maps the table columns into a simple schema array.
export function generateNodes(dataSchema) {
  const nodes = [];
  const offsetX = 300; // horizontal spacing
  const offsetY = 150; // vertical spacing
  let index = 0;

  Object.keys(dataSchema).forEach((tableKey) => {
    const table = dataSchema[tableKey];

    // Create a node for this table.
    nodes.push({
      id: `${index}_${tableKey}`,
      type: 'databaseSchema', // your custom React Flow node type
      data: {
        label: table.table_name,
        schema: table.columns.map((col) => ({
          title: col.name,
          type: col.data_type,
        })),
      },
      position: {
        // Use a simple grid layout for positions.
        x: (index % 3) * offsetX + 50,
        y: Math.floor(index / 3) * offsetY + 50,
      },
    });
    index++;
  });

  return nodes;
}

// Generate edges based on foreign key relationships.
// This function looks at the foreign_keys string for each table,
// extracts the foreign key column(s), and then searches for any table
// whose primary_keys contain the same column name.
export function generateEdges(dataSchema) {
  const edges = [];

  // Create a map of table key to an array of primary key columns.
  const primaryKeysMap = {};
  Object.keys(dataSchema).forEach((tableKey) => {
    const table = dataSchema[tableKey];
    // Remove parentheses and split by comma in case there are multiple keys.
    const pkStr = table.primary_keys || '';
    const pkCols = pkStr
      .replace(/[()]/g, '')
      .split(',')
      .map((s) => s.trim())
      .filter(Boolean);
    primaryKeysMap[tableKey] = pkCols;
  });

  // For each table with foreign keys, create an edge for each foreign key column
  // that matches a primary key in another table.
  Object.keys(dataSchema).forEach((sourceKey) => {
    const table = dataSchema[sourceKey];
    if (table.foreign_keys && table.foreign_keys.trim() !== '') {
      const fkCols = table.foreign_keys
        .replace(/[()]/g, '')
        .split(',')
        .map((s) => s.trim())
        .filter(Boolean);

      fkCols.forEach((fkCol) => {
        // Look for a target table whose primary keys include this foreign key column.
        Object.keys(dataSchema).forEach((targetKey, i) => {
          // Optionally skip self-references.
          if (targetKey === sourceKey) return;

          const targetPks = primaryKeysMap[targetKey];
          if (targetPks.includes(fkCol)) {
            edges.push({
              id: `${i}-${sourceKey}-${targetKey}-${fkCol}`,
              source: sourceKey,
              target: targetKey,
              label: fkCol, // you can use this label in your node or edge tooltip if desired
              type: 'smoothstep',
            });
          }
        });
      });
    }
  });

  return edges;
}
