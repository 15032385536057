import React, { memo } from 'react';
import { Position } from '@xyflow/react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import {
  DatabaseSchemaNode,
  DatabaseSchemaNodeHeader,
  DatabaseSchemaNodeBody,
  DatabaseSchemaTableRow,
  DatabaseSchemaTableCell,
} from './node';
import { HandleStyled } from './styled';

const DatabaseSchemaDemo = memo(({ data, selected }) => {
  return (
    <DatabaseSchemaNode selected={selected}>
      <DatabaseSchemaNodeHeader>{data.label}</DatabaseSchemaNodeHeader>
      <DatabaseSchemaNodeBody>
        {data.schema.map((entry) => (
          <DatabaseSchemaTableRow key={entry.title}>
            <DatabaseSchemaTableCell>
              <HandleStyled
                type="target"
                position={Position.Left}
                id={`${entry.title}-target`}
              />
              <Typography variant="caption">{entry.title}</Typography>
            </DatabaseSchemaTableCell>
            <DatabaseSchemaTableCell>
              <Typography variant="caption">{entry.type}</Typography>
              <HandleStyled
                type="source"
                position={Position.Right}
                id={`${entry.title}-source`}
              />
            </DatabaseSchemaTableCell>
          </DatabaseSchemaTableRow>
        ))}
      </DatabaseSchemaNodeBody>
    </DatabaseSchemaNode>
  );
});

DatabaseSchemaDemo.propTypes = {
  data: PropTypes.object,
  selected: PropTypes.bool,
};

DatabaseSchemaDemo.displayName = 'DatabaseSchemaDemo';

export default DatabaseSchemaDemo;
