import {
  Card,
  CardContent,
  CardHeader,
  styled,
  TableCell,
  TableRow,
} from '@mui/material';
import { Handle } from '@xyflow/react';

import colors from '../../../../themes/colors';

export const HandleStyled = styled(Handle)(({ theme }) => ({
  background: colors.neutralLight.N200,
  height: 8,
  width: 8,
}));

export const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.text.secondary,
  padding: '8px',
}));

export const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: 0,
}));

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  fontSize: '0.75rem',
}));

export const TableCellStyled = styled(TableCell)(({ theme }) => ({
  padding: '8px',
}));

export const CardStyled = styled(Card)(({ theme, selected }) => ({
  borderColor: selected ? theme.palette.primary.main : 'transparent',
  minWidth: 250,
}));
